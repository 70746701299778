import React from "react";
import "./LoginDescription.css";
import comma from "../../assets/login/comma.svg";
import star from "../../assets/login/Star.svg";
import img1 from "../../assets/login/img1.svg";
import img2 from "../../assets/login/img2.svg";
import img3 from "../../assets/login/img3.svg";
import star2 from "../../assets/login/star2.svg";

const LoginDescription = ({ descriptionImg }) => {
  return (
    <div className="loginDescription  ">
      <div className="content">
        <img src={comma} alt="" />
        <p>
          <span>
          Excellent platform to put what you learn into practice, that too chapter after chapter. 
          </span>
          
        </p>
        <div className="review">
          <p> - Abhijith K (UPSC prepBubble)</p>
          <div className="rating">
            <img src={star2} alt="star" />
            <img src={star2} alt="star" />
            <img src={star2} alt="star" />
            <img src={star2} alt="star" />
            <img src={star2} alt="star" />
          </div>
        </div>
        <div className="reviewer">
          <img className="img1" src={img3} alt="image 2" />
          <img className="img2" src={img1} alt="image 1" />
          <img className="img3" src={img2} alt="image 3" />
        </div>
      </div>

      <div className=" ml-[7vw]  absolute top-[55vh] ">
        PrepBubble is designed for an exceptional user experience on laptops and tablets.
      </div>

      <img className="description-img" src={descriptionImg} alt="" />
    </div>
  );
};

export default LoginDescription;
