import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { notification } from 'antd';
import {
  ERROR_LOGIN,
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  NOTIFICATION_TYPE_ERROR,
  NOTIFICATION_TYPE_SUCCESS,
  SUCCESS_LOGIN,
  SUCCESS_SIGNUP,
  SUCCESS_SIGNUP_DESC,
} from '../constants/ActionTypes';
import service from './FetchInterceptor';
import { env } from '../helpers/EnvironmentConfig';
import { ErrorSuccessHandler } from '../helpers/ErrorSuccessHandler';
import { toast, ToastContainer } from 'react-toastify';
const JWTAuthContext = createContext();
const JWTAuthActionsContext = createContext();

export const useJWTAuth = () => useContext(JWTAuthContext);

export const useJWTAuthActions = () => useContext(JWTAuthActionsContext);

const AuthProvider = ({ children }) => {
  const [firebaseData, setJWTAuthData] = useState({
    user: null,
    isAuthenticated: false,
    isLoading: true,
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const getAuthUser = () => {
      const token = localStorage.getItem('access_token');
      if (!token) {
        setJWTAuthData({
          user: undefined,
          isLoading: false,
          isAuthenticated: false,
        });
        navigate('/');
        return;
      } else {
        setJWTAuthData({
          user: localStorage.getItem('user_Details'),
          isLoading: false,
          isAuthenticated: true,
        });
      }
    };
    getAuthUser();
  }, []);

  const signInUser = async (request) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await service.post(env.API_ENDPOINT_URL + 'participant/ident', request);
      
      if (response.status) {
        localStorage.setItem('access_token', response.data?.access_token);
        localStorage.setItem('isAuthenticated', true);
        localStorage.setItem("user_identity_id", response.data?.id)
        
        const storage_User = {
          first_name: response.data?.first_name,
          assigned_plan_ref: response.data?.assigned_plan_ref,
          current_plan_expiry: response.data?.current_plan_expiry
        }
        localStorage.setItem('user_Details', JSON.stringify(storage_User));
        setJWTAuthData({ user: storage_User, isAuthenticated: true, isLoading: false });
        navigate('/dashboard');
        notification[NOTIFICATION_TYPE_SUCCESS]({
          message: SUCCESS_LOGIN,
          description: '',
        });
        dispatch({ type: FETCH_SUCCESS });
      }
    } catch (error) {
      localStorage.clear();
      setJWTAuthData({
        ...firebaseData,
        isAuthenticated: false,
        isLoading: false,
      });
      dispatch({ type: FETCH_ERROR, payload: error.message });
      notification[NOTIFICATION_TYPE_ERROR]({
        message: ERROR_LOGIN,
        description: '',
      });
    }
  };

  const signUpUser = async (request) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await service.post(env.API_ENDPOINT_URL + 'participant/start-journey', request);
      if (response.success) {
        notification[NOTIFICATION_TYPE_SUCCESS]({
          message: SUCCESS_SIGNUP,
          description: SUCCESS_SIGNUP_DESC,
        });
        dispatch({ type: FETCH_SUCCESS });
        return true;
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      toast.warning("User already exists")
      return false;
    }
  };

  const otpVerify = async (request) => {
    dispatch({ type: FETCH_START });
    try {
      const response = await service.post(env.API_ENDPOINT_URL + 'participant/verify-mobile', request);
      if (response.success) {
        dispatch({ type: FETCH_SUCCESS });
        ErrorSuccessHandler('Sign Up Successfully Completed.!!', 'success');
        
        setTimeout(() => {
          window.location.href = '/login';
        }, 2000); // 2-second delay
  
        return true;
      }
    } catch (error) {
      dispatch({ type: FETCH_ERROR, payload: error.message });
      return false;
    }
  };
  

  const logout = async () => {
    navigate('/');
    localStorage.clear();
    setJWTAuthData({
      user: null,
      isLoading: false,
      isAuthenticated: false,
    });
  };

  return (
    <JWTAuthContext.Provider
      value={{
        ...firebaseData,
      }}>
      <JWTAuthActionsContext.Provider
        value={{
          signUpUser,
          signInUser,
          otpVerify,
          logout,
        }}>
        {children}
        <ToastContainer />
      </JWTAuthActionsContext.Provider>
    </JWTAuthContext.Provider>
  );
};
export default AuthProvider;

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
