import React from "react";
import { useState, useEffect } from "react";
import QuizGraph from "./QuizGraph.js";
import { FiCheck } from "react-icons/fi";

const QuizInfo = ({
  totalMinutes,
  currentQuestion,
  maxQuestions,
  setCurrReadingStage,
  currReadingStage,
  fullTest,
  setFullTest,
}) => {
  const [minutes, setMinutes] = useState(fullTest ? totalMinutes : 0);
  const [seconds, setSeconds] = useState(0);
  const [quesChartData, setQuesChartData] = useState({
    datasets: [
      {
        label: "",
        data: [currentQuestion, maxQuestions - currentQuestion],
        backgroundColor: ["#FF9900", "#9648AD"],
        borderColor: ["#FF9900", "#9648AD"],
        borderWidth: 1,
      },
    ],
  });

  const [timeChartData, setTimeChartData] = useState({
    datasets: [
      {
        label: "",
        data: [
          minutes * 60 + seconds,
          totalMinutes * 60 - (minutes * 60 + seconds),
        ],
        backgroundColor: ["#FF9900", "#9648AD"],
        borderColor: ["#FF9900", "#9648AD"],
        borderWidth: 1,
      },
    ],
  });

  const [surity, setSurity] = useState([]);

  useEffect(() => {
    if (fullTest) {
      let myInterval = setInterval(() => {
        if (minutes > 0) {
          if (seconds > 0) {
            setSeconds(seconds - 1);
          }
          if (seconds === 0) {
            if (minutes === 0) {
              clearInterval(myInterval);
            } else {
              setMinutes(minutes - 1);
              setSeconds(59);
            }
          }
        } else if (minutes === 0 && seconds > 0) {
          if (seconds > 0) {
            setSeconds(seconds - 1);
          } else {  
            clearInterval(myInterval);
          }
        } else {
          clearInterval(myInterval);
        }

        setTimeChartData({
          datasets: [
            {
              label: "",
              data: [
                minutes * 60 + seconds,
                totalMinutes * 60 - (minutes * 60 + seconds),
              ],
              backgroundColor: ["#FF9900", "#9648AD"],
              borderColor: ["#FF9900", "#9648AD"],
              borderWidth: 1,
            },
          ],
        });
      }, 1000);

      return () => {
        clearInterval(myInterval);
      };
    } else {
      let myInterval = setInterval(() => {
        if (minutes < totalMinutes) {
          if (seconds < 59) {
            setSeconds(seconds + 1);
          }
          if (seconds === 59) {
            if (minutes === totalMinutes) {
              clearInterval(myInterval);
            } else {
              setMinutes(minutes + 1);
              setSeconds(0);
            }
          }
        } else {
          clearInterval(myInterval);
        }

        setTimeChartData({
          datasets: [
            {
              label: "",
              data: [
                minutes * 60 + seconds,
                totalMinutes * 60 - (minutes * 60 + seconds),
              ],
              backgroundColor: ["#FF9900", "#9648AD"],
              borderColor: ["#FF9900", "#9648AD"],
              borderWidth: 1,
            },
          ],
        });
      }, 1000);

      return () => {
        clearInterval(myInterval);
      };
    }
    // let myInterval = setInterval(() => {
    //   if (minutes < totalMinutes) {
    //     if (seconds < 59) {
    //       setSeconds(seconds + 1);
    //     }
    //     if (seconds === 59) {
    //       if (minutes === totalMinutes) {
    //         clearInterval(myInterval);
    //       } else {
    //         setMinutes(minutes + 1);
    //         setSeconds(0);
    //       }
    //     }
    //   } else {
    //     clearInterval(myInterval);
    //   }

    //   setTimeChartData({
    //     datasets: [
    //       {
    //         label: "",
    //         data: [
    //           minutes * 60 + seconds,
    //           totalMinutes * 60 - (minutes * 60 + seconds),
    //         ],
    //         backgroundColor: ["#FF9900", "#9648AD"],
    //         borderColor: ["#FF9900", "#9648AD"],
    //         borderWidth: 1,
    //       },
    //     ],
    //   });
    // }, 1000);

    // return () => {
    //   clearInterval(myInterval);
    // };
  });

  useEffect(() => {
    const newAttendedQuesCnt = currentQuestion;

    const newUnattendedQuesCnt = maxQuestions - currentQuestion;

    setQuesChartData({
      datasets: [
        {
          label: "",
          data: [newAttendedQuesCnt, newUnattendedQuesCnt],
          backgroundColor: ["#FF9900", "#9648AD"],
          borderColor: ["#FF9900", "#9648AD"],
          borderWidth: 1,
        },
      ],
    });
  }, [currentQuestion]);

  useEffect(() => {
    const newSurity = [];
    for (let i = 0; i < maxQuestions; i++) {
      newSurity.push({
        key: i,
        check1: true,
        check2: false,
        check3: false,
        check4: false,
      });
    }
    setSurity(newSurity);
  }, [maxQuestions]);

  useEffect(() => {
    console.log(setCurrReadingStage);
  }, [setCurrReadingStage]);
  return (
    <div className="w-1/3 pr-24 flex flex-col items-center font-Inter non-Italic lg:w-[100%] lg:pr-0 lg:flex-row lg:justify-between sm:flex-col">
      <div className="my-6 flex-col w-[100%]">
      <p className="text-2xl font-bold mb-4">How confident are you?</p>
        <div className="w-[100%] flex  items-center my-2">
         
          <div className="w-14 h-14 text-white mx-2 rounded-full bg-green-500 flex justify-center items-center">
            R1
          </div>
          <p className="mx-4 text-black text-[14px] leading-[17px] font-medium w-[90px]">
            100% Sure
          </p>
          <input
            type="radio"
            name="surity"
            id="check1"
            checked={currReadingStage == 1 ? true : false}
            onClick={(e) => {
              setCurrReadingStage(1);
            }}
          />
        </div>
        <div className="w-[100%] flex  items-center my-2">
          <div className="w-14 h-14 text-white mx-2 rounded-full bg-blue-500 flex justify-center items-center">
            R2
          </div>
          <p className="mx-4 text-black text-[14px] leading-[17px] font-medium w-[90px]">
            75% Sure
          </p>
          <input
            type="radio"
            name="surity"
            id="check2"
            checked={currReadingStage == 2 ? true : false}
            onClick={(e) => {
              const newSurity = { ...surity };
              const checkBoxId = e.target.id;
              newSurity[currentQuestion - 1]["check1"] = false;
              newSurity[currentQuestion - 1]["check2"] = false;
              newSurity[currentQuestion - 1]["check3"] = false;
              newSurity[currentQuestion - 1]["check4"] = false;
              newSurity[currentQuestion - 1][checkBoxId] = true;
              setSurity(newSurity);

              setCurrReadingStage(2);
            }}
          />
        </div>
        <div className="w-[100%] flex  items-center my-2">
          <div className="w-14 h-14 text-white mx-2 rounded-full bg-yellow-500 flex justify-center items-center ">
            R3
          </div>
          <p className="mx-4 text-black text-[14px] leading-[17px] font-medium w-[90px]">
            50% Sure
          </p>
          <input
            type="radio"
            name="surity"
            id="check3"
            checked={currReadingStage == 3 ? true : false}
            onClick={(e) => {
              const newSurity = { ...surity };
              const checkBoxId = e.target.id;
              newSurity[currentQuestion - 1]["check1"] = false;
              newSurity[currentQuestion - 1]["check2"] = false;
              newSurity[currentQuestion - 1]["check3"] = false;
              newSurity[currentQuestion - 1]["check4"] = false;
              newSurity[currentQuestion - 1][checkBoxId] = true;

              setCurrReadingStage(3);

              setSurity(newSurity);
            }}
          />
        </div>

        <div className="w-[100%] flex  items-center my-2">
          <div className="w-14 h-14 text-white mx-2 rounded-full bg-orange-500 flex justify-center items-center">
            R4
          </div>
          <p className="mx-4 text-black text-[14px] leading-[17px] font-medium w-[90px]">
            Try your luck
          </p>
          <input
            type="radio"
            name="surity"
            id="check4"
            checked={currReadingStage == 4 ? true : false}
            onClick={(e) => {
              const newSurity = { ...surity };
              const checkBoxId = e.target.id;
              newSurity[currentQuestion - 1]["check1"] = false;
              newSurity[currentQuestion - 1]["check2"] = false;
              newSurity[currentQuestion - 1]["check3"] = false;
              newSurity[currentQuestion - 1]["check4"] = false;
              newSurity[currentQuestion - 1][checkBoxId] = true;

              setCurrReadingStage(4);

              setSurity(newSurity);
            }}
          />
        </div>
      </div>
      <div className="bg-[#FAFAFA] rounded-[20px] w-[100%] pt-6 flex flex-col items-start pb-10 pr-6 lg:w-2/3 lg:pl-6 sm:w-[100%]">
        <div className="flex items-center mb-4">
          <div className="flex items-center justify-center w-36 h-36  mx-4 relative">
            <QuizGraph chartData={timeChartData} />{" "}
            <span className="absolute top-[43%] left-[26%] text-black text-[16px] font-semibold">
              {minutes < 10 ? `0${minutes}` : minutes}:
              {seconds < 10 ? `0${seconds}` : seconds}
            </span>{" "}
          </div>

          <div className="ml-4">
            <h1 className="font-medium text-black text-[16px] my-2 leading-[19px]">
              Time taken
            </h1>
            <p className="font-light text-[#303030] text-[14px] leading-[17px]">
              Finish as fast as You Can
            </p>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex items-center justify-center w-36 h-36  mx-4 relative">
            <QuizGraph chartData={quesChartData} />{" "}
            <span className="absolute top-[41%] left-[38%] text-black text-[16px] font-semibold">
              {maxQuestions - currentQuestion < 10
                ? `0${maxQuestions - currentQuestion}`
                : maxQuestions - currentQuestion}
            </span>{" "}
          </div>
          <div className="ml-4">
            <h1 className="font-medium text-black text-[16px] my-2 leading-[19px]">
              Question left to attend
            </h1>
            <p className="font-light text-[#303030] text-[14px] leading-[17px]">
              Finish as much as You Can
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col w-[100%] my-12  lg:px-12 lg:w-1/3 sm:w-[100%]">
        <div className="flex justify-start w-[100%] mx-2 my-4 items-center">
          <div className="mr-8 justify-center items-center flex min-w-[26px] max-w-[26px]">
            <div className=" w-[26px] h-[26px] rounded-full "></div>
          </div>
          <p className="font-medium text-black text-[16px] leading-[19px]">
            Total Questions: {maxQuestions}
          </p>
        </div>
        <div className="flex justify-start w-[100%] mx-2 my-4 items-center">
          <div className="flex justify-center items-center min-w-[26px] min-h-[26px] mr-8">
            <div className="bg-green-500 w-[26px] h-[26px] rounded-full"></div>
          </div>
          <p className="font-medium text-black text-[16px] leading-[19px]">
            Attended
          </p>
        </div>

        <div className="flex justify-start w-[100%] mx-2 my-4 items-center">
          <div className="flex justify-center items-center min-w-[26px] min-h-[26px] mr-8">
            <div className="bg-[#5D44DE] w-[26px] h-[26px] rounded-full"></div>
          </div>
          <p className="font-medium text-black text-[16px] leading-[19px]">
            Viewed Questions
          </p>
        </div>
     
     
        <div className="flex justify-start w-[100%] mx-2 my-4 items-start">
          <div className="flex justify-center items-center min-w-[26px] min-h-[26px] mr-8">
            <div className="bg-[#E9F5FE] w-[26px] h-[26px] rounded-full "></div>
          </div>
          <p className="font-medium text-black text-[16px] leading-[19px]">
            Yet to Answer
          </p>
        </div>

        <div className="flex justify-start w-[100%] items-center mt-6">
          <div className="flex justify-center items-center min-w-14 min-h-14">
            <div className="bg-white rounded-full border-[#5D44DE] border-[7px]  w-14 h-14  flex justify-center items-center mr-6">
              <FiCheck size="10px" color={"#5D44DE"} stroke-width="4" />
            </div>
          </div>
          <p className="font-medium text-black text-[16px] leading-[19px]">
            Selected Answer
          </p>
        </div>
      </div>
    </div>
  );
};

export default QuizInfo;
