import axios from "axios";
import React, { useState } from "react";
import "./reportQuestion.css";
import { env } from "../../@common/helpers/EnvironmentConfig";

const ReportQuestion = ({ setShowModal, question }) => {
  const [review, setReview] = useState(false);

  async function reportQuestion(questionId, review) {
    const token = localStorage.getItem("access_token");
  const header_token = {
    headers: { Authorization: `Bearer ${token}` },
  };
  const url = env.API_ENDPOINT_URL + `participant/report/report-question`;
    
    try {
      const resp = await axios.post(url, {
        question_id: questionId,
        report_reason: review,
      }, header_token);

      setShowModal(false);
      if (resp.data.status === "success") {
        console.log("success");
        setShowModal(false);
      }
    } catch (err) {}
  }
  return (
    <div className="overlay">
      <div className="report-container">
        <h2 style={{ fontWeight: "bold" }}>Report Question</h2>
        <div
          className="question"
          dangerouslySetInnerHTML={{
            __html: question.data.data.question.questionTitle,
          }}
        ></div>
        <br></br>
        <textarea
          className="text-area-to-report"
          placeholder="Reason to Report"
          onChange={(e) => setReview(e.target.value)}
        ></textarea>
        <div className="gap"></div>
        <button
          onClick={() => setShowModal(false)}
          style={{ marginRight: "5px" }}
          className=" btn btn-primary"
        >
          Cancel
        </button>
        <button
          onClick={() => reportQuestion(question.data.data.question_ref, review)}
          className="btn btn-danger"
        >
          Report
        </button>
      </div>
    </div>
  );
};

export default ReportQuestion;
