import React from "react";
import axios from "axios";
import { env } from "../../../@common/helpers/EnvironmentConfig";
import "./Pricing.css";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import greenTIck from "../../../assets/videos/greenTick.svg";
import FAQ from "../../FAQ/FAQ";
import useRazorpay from "react-razorpay";
import { json } from "react-router-dom";
import swal from "sweetalert";

const PricingFAQ = [
  {
    id: 1,
    question: "What forms of payment are accepted by prepBubble?",
    answer:
      "We accept both Debit and Credit cards for purchasing our subscription package.",
  },
  {
    id: 2,
    question: "Do you support EMI or other monthly installment options?",
    answer:
      "Although we do not presently provide EMI alternatives, you can use your credit card to make a purchase and then convert it to EMI or monthly installments later according to your bank offers.",
  },
  {
    id: 3,
    question: "Is there an auto debit feature for monthly subscription?",
    answer: "No, we do not currently provide this feature.",
  },
  {
    id: 4,
    question: "Will I be able to cancel the subscription and get a refund?",
    answer:
      "Yes, you can cancel the subscription anytime. However, all purchases made are final and are nonrefundable.",
  },
  {
    id: 5,
    question:
      "Will I be able to switch to any other package while I am in any running package?",
    answer:
      "No, if you already have a running subscription package, you cannot switch to another one with the same account.",
  },
  {
    id: 6,
    question: "What is the expiration duration of each subscription package?",
    answer:
      "Bronze: 30 days starting from the date and time of purchase\nSilver: 90 starting days from the date and time of purchase\nGold: 180 days starting from the date and time of purchase",
  },
  {
    id: 7,
    question: "Can I roll over the unused number of tests to my next purchase?",
    answer: "No, you cannot transfer any unused tests to your next purchase.",
  },
  {
    id: 8,
    question: "Is there a cap on the number of tests you can take each day?",
    answer:
      "For Bronze and Silver the upper limit for the total number of tests is 10 full tests and 10 subject-wise tests per day. For Gold, the upper limit is 25 full tests and 20 subject-wise tests per day.",
  },
  {
    id: 9,
    question:
      "How many systems or devices can be used to login from a single account?",
    answer: "You can only log in using one device at a time.",
  },
  {
    id: 10,
    question:
      "Will we be able to make bulk purchases, and is there any discount for the same?",
    answer:
      "Yes, bulk purchases are available for silver and gold subscription packages. Also note the minimum quantity for bulk purchase is 10 nos. (user ID’s). Discounts will be approved on a case-to-case basis. If you are an interested Individual, Institution, or Reading/Study room, please drop your inquiries to aspirantlearning3@gmail.com",
  },
  {
    id: 11,
    question:
      "How can I contact prepBubble for queries related to account/payment issues?",
    answer:
      "For technical issues like payment issues, test not working, responsivity of screen, login error etc. please mail to: support@aspirantlearning.com For content-related issues, use the report button appearing on top of every question. For general inquiries about our products, please write to: aspirantlearning3@gmail.com",
  },
  {
    id: 12,
    question: "How does Free trial work?",
    answer:
      "The free trial period lasts for 30 days, or until the free test finishes, whichever occurs first. You must subscribe to a premium plan in order to use the platform going forward.",
  },
];

const showAlert = () => {
  swal({
    title: "Please Login",
    text: "You can purchase any plan after login.",
    icon: "warning",
    buttons: true,
    dangerMode: true,
  });
};

const Pricing = () => {
  document.title = "Pricing | prepBubble";
  document.getElementsByTagName("body")[0].style.overflowY = "scroll";

  const token = localStorage.getItem("access_token");
  const header_token = {
    headers: { Authorization: `Bearer ${token}` },
  };

  const [Razorpay] = useRazorpay();

  async function getPaymentId(price) {
    const url = env.API_ENDPOINT_URL + `participant/payment/start`;

    const userDetails = localStorage.getItem("user_Details");
    if (userDetails === null) {
      showAlert();
    } else {
      try {
        let userId = localStorage.getItem("user_identity_id");

        const config = {
          userId: userId,
          amount: parseInt(price),
        };
        const resp = await axios.post(url, config, header_token);
        return resp.data.data.id; //returning the order_id of razorPay
        // return resp.data.data.transaction_id;
      } catch (err) {
        showAlert();
      }
    }
  }

  const handlePayment = async (price) => {
    const paymentId = await getPaymentId(price); //  Create order on your backend

    if (paymentId) {
      let userDetails = localStorage.getItem("user_Details");
      userDetails = JSON.parse(userDetails);
      console.log("user Details", userDetails);

      const options = {
        key: process.env.RAZORPAY_URL, 
        amount: price, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        currency: "INR",
        name: "prepBubble",
        description: "Subscription prepBubble",
        image:
          "https://www.aspirantlearning.com/static/media/logo.e62d09c28b35deee3d99.png",
        order_id: paymentId, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
        handler: function (response) {
          // alert(response.razorpay_payment_id);
          // alert(response.razorpay_order_id);
          // alert(response.razorpay_signature);
        },
        prefill: {
          name: userDetails.first_name,
          contact: userDetails.phone
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#7459ca",
        },
      };

      const rzp1 = new Razorpay(options);

      // rzp1.on("payment.failed", function (response) {
      //   alert(response.error.code);
      //   alert(response.error.description);
      //   alert(response.error.source);
      //   alert(response.error.step);
      //   alert(response.error.reason);
      //   alert(response.error.metadata.order_id);
      //   alert(response.error.metadata.payment_id);
      // });

      rzp1.open();
    }
  };

  return (
    <>
      <Header />

      <div>
        <section className="pricing-section">
          <div className="pricing-bg">
            <h2 className="price-main-heading">
              Everyone prepares differently it is why prepBubble is
              committed to helping you prepare at your own pace and time
            </h2>
            <h2
              style={{ textTransform: "uppercase" }}
              className="price-main-heading animate-bounce"
              id="price-main-heading-id"
            >
              Inaugural Offer
            </h2>
          </div>
          <div className="container-fluid">
            <div className="container1">
              <div className="row1">
                <div className="columns1">
                  <ul className="price1">
                    <li className="header1">BENEFITS</li>
                    <li className="head-text-li">Period</li>
                    <li className="head-text-li">Previous Year Paper</li>
                    <li className="head-text-li">No. of full test</li>
                    <li className="head-text-li">No. of chapterwise test</li>
                    <li className="head-text-li">Note Maker</li>
                    <li className="head-text-li">Learning Time Tracker</li>
                    
                    <li className="head-text-li">
                      Current Affairs Compilation
                    </li>
                    <li className="head-text-li">Price</li>
                    <li className="head-text-li">Discounts</li>
                    <li className="head-text-li">Price after Discount</li>
                  </ul>
                </div>

                <div className="columns">
                  <ul className="price">
                    <li
                      style={{ backgroundColor: "#00c514" }}
                      className="header"
                    >
                      FREE TRIAL
                    </li>
                    <li>30 Days</li>
                    <li>1</li>
                    <li>1</li>
                    <li>2</li>
                    <li>Full Access</li>
                    <li>Full Access</li>
                    <li>-</li>
                    <li>Free</li>
                    <li>-</li>
                    <li className="price-final">Free <br></br> <span className="text-2xl text-white">(Free)</span></li>
                    <li
                      style={{
                        backgroundColor: "#00c514",
                        color: "white",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      Choose
                    </li>
                  </ul>
                </div>

                <div className="columns">
                  <ul className="price">
                    <li
                      style={{ backgroundColor: "#d5c300" }}
                      className="header"
                    >
                      EXPLORER
                    </li>
                    <li>1 month</li>
                    <li>2</li>
                    <li>25</li>
                    <li>22</li>
                    <li>Full Access</li>
                    <li>Full Access</li>
                    <li>-</li>
                    <li>₹ 1099</li>
                    <li className="text-green-600 font-bold">18%</li>
                    <li className="price-final">₹ 899 <br></br> <span className="text-2xl text-white">(₹899)</span></li>
                    <li
                      onClick={() => handlePayment("899000")}
                      style={{
                        backgroundColor: "#d5c300",
                        color: "white",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      Choose
                    </li>
                  </ul>
                </div>

                <div className="columns">
                  <ul className="price">
                    <li
                      style={{ backgroundColor: "#217cec" }}
                      className="header"
                    >
                      ACHIEVER
                    </li>
                    <li>12 months</li>
                    <li>All tests</li>
                    <li>225</li>
                    <li>150</li>
                    <li>Full Access</li>
                    <li>Full Access</li>
                    <li>-</li>
                    <li>₹ 10,788</li>
                    <li className="text-green-600 font-bold">75%</li>
                    <li  className="price-final">₹ 2700 <br></br> <span className="text-2xl">(₹225/ month)</span></li>
                    <li
                      onClick={() => handlePayment("2700000")}
                      style={{
                        backgroundColor: "#217cec",
                        color: "white",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                    >
                      Choose
                    </li>
                  </ul>
                </div>
                <div className="columns">
                  <ul className="price">
                    <li
                      style={{ backgroundColor: "#f4810a" }}
                      className="header"
                    >
                      MASTER
                    </li>
                    <li>18 months</li>
                    <li>All Tests</li>
                    <li>Unlimited</li>
                    <li>All Tests</li>
                    <li>Full Access</li>
                    <li>Full Access</li>
                    <li>Priroty Access</li>
                    <li>₹ 16,182</li>
                    <li className="text-green-600 font-bold">77%</li>
                    <li className="price-final">₹ 3830 <br></br> <span className="text-2xl">(₹213/ month)</span></li>
                    <li
                      onClick={() => handlePayment("3830000")}
                      style={{
                        backgroundColor: "#f4810a",
                        color: "white",
                        fontSize: "18px",
                        cursor: "pointer",
                      }}
                      className="header"
                    >
                      Choose
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="note-container list-disc">
            <h4>NOTE</h4>
            <ul className="list-disc">
              <li>
              • The prices listed above are part of our inaugural offer and are
                only valid for the first 1500 users or the offer will be
                extended at the discretion of the management.
              </li>
              <li>
              • GST is included in the rates shown above, and they are only
                available while the promotion is in effect.
              </li>
              <li>
              • Processing fees, which can account for up to 1% - 2% of the total
                bill, are not included in the rates shown above.
              </li>

              <li>
              • PrepBubble is designed for an exceptional user experience on laptops and tablets.
              </li>
            </ul>
          </div>


          
          
        </section>


        
        <div className="faq-pricing-container">
          <h1 className="pricing-faq">Pricing FAQ</h1>
          <FAQ questions={PricingFAQ} />
        </div>
      </div>




      {/* Area 3*/}

      <Footer />
    </>
  );
};

export default Pricing;
